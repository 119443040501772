@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F6F6F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
}
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}

.lay {
  width: 98%;
  margin: 0 auto;
  .ant-table {
    background: transparent !important;
  }
  th {
    font-weight: bold;
  }
  th, td {
    font-size: 1vw;
    padding: 6px !important;
    background: transparent !important;
    color: #fff !important;
    font-weight: bold !important;
    font-family: 'Microsoft YaHei';
    strong {
      color: #fff;
    }
  }
  td {
    padding: 4px !important;
  }
}
