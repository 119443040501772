// Color
$primary-color          : #2a98fe;

$info-color             : #2db7f5;
$success-color          : #19be6b;
$warning-color          : #ff9900;
$error-color            : #ed4014;
$normal-color           : #e6ebf1;
$link-color             : #2D8cF0;
$link-hover-color       : lighten($link-color, 20%);
$link-active-color      : darken($link-color, 5%);
$selected-color         : rgba($primary-color, 0.8);
$tooltip-color          : #fff;
$subsidiary-color       : #808695;
$rate-star-color        : #f5a623;


// Base
$body-background        : #fff;
$font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
$code-family            : Consolas,Menlo,Courier,monospace;
$title-color            : #17233d;
$text-color             : #515a6e;
$font-size-base         : 14px;
$font-size-small        : 12px;
$font-size-large        : 16px;
$line-height-base       : 1.5;
$line-height-computed   : floor(($font-size-base * $line-height-base));
$border-radius-base     : 6px;
$border-radius-small    : 4px;
$cursor-disabled        : not-allowed;


// Border
$border-color-base      : #e8e8e8;  // outside
$border-color-split     : #e8eaec;  // inside
$border-width-base      : 1px;            // width of the border for a component
$border-style-base      : solid;          // style of a components border
$border-base            : $border-width-base $border-style-base $border-color-base;

// Background color
$background-color-base  : #ffffff;  // base


// Shadow
$shadow-color           : rgba(0, 0, 0, .2);
$shadow-base            : 0 0px 6px $shadow-color;
$shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
$shadow-up              : 0 -1px 6px $shadow-color;
$shadow-down            : 0 1px 6px $shadow-color;
$shadow-left            : -1px 0 6px $shadow-color;
$shadow-right           : 1px 0 6px $shadow-color;


// Z-index
$zindex-spin            : 8;
$zindex-affix           : 10;
$zindex-back-top        : 10;
$zindex-select          : 900;
$zindex-modal           : 1000;
$zindex-drawer          : 1000;
$zindex-message         : 1010;
$zindex-notification    : 1010;
$zindex-tooltip         : 1060;
$zindex-transfer        : 1060;
$zindex-loading-bar     : 2000;
$zindex-spin-fullscreen : 2010;

// Animation
$animation-time         : .3s;
$transition-time        : .2s;
$ease-in-out : ease-in-out;
.box {
  height: 100%;
  background: url(../../assets/images/bg.png)no-repeat;
  background-size: cover;
  padding-top: 160px;
  .no {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  table {
    border-collapse: collapse;
    width: 98%;
    margin: 0 auto;
    th, td {
      font-size: 1vw;
      padding: 6px;
      color: #fff;
      font-weight: bold;
      font-family: 'Microsoft YaHei';
      border: solid 1px #fff;
      strong {
        color: #fff;
      }
    }
    td {
      padding: 4px;
    }
  }
}

.animation {
  height: 100%;
  background: rgb(111, 3, 3) url(../../assets/images/bg.png)no-repeat;
  background-size: 100% 100%;
  padding-top: 180px;
  .animationTab {
    width: 98%;
    margin: 0 auto;
    display: flex;
    border: 1px solid #fff;
  }
  .flex {
    h1 {
      font-size: 1vw;
      padding: 6px;
      color: #fff;
      font-weight: bold;
      font-family: 'Microsoft YaHei';
      border: solid #fff;
      border-width: 1px;
      height: 4vw;
      strong {
        color: #fff;
      }
      em {
        display: block;
        font-style: normal;
        font-size: .9vw;
      }
    }
    p {
      font-size: 1vw;
      color: #fff;
      padding: 6px;
      border: solid #fff;
      border-width: 1px;
      margin: 0;
      position: relative;
      height: 4vw;
      strong {
        color: #fff;
      }
      img {
        width: 8vw;
        position: absolute;
        left: 0;
        bottom: -2vw;
        opacity: 0;
        @keyframes back {
          0% {
            opacity: 1;
            
          }
          80% {
            opacity: 1;
            
          }
          100% {
            opacity: 0;
            
          }
        }
        
        animation-name: back;
        animation-duration: 1s;
        
      }
      
    }
  }
}

.chart {
  display: flex;
  .item {
    position: relative;
    height: 100vh;
    padding-top: 50px;
    flex: 1;
    h2 {
      width: 100%;
      font-size: 30px;
      font-weight: bold;
      color: transparent;
      background-clip: text;
      position: absolute;
      top: 20px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Microsoft YaHei';
      img {
        height: 26px;
        margin-right: 6px;
      }
    }
    &:nth-of-type(odd) {
      background-image: linear-gradient( to bottom, #ffbe18, #cd5510);
      h2 {
        background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ac8201), to(#54440e));
      }
    }
    &:nth-of-type(even) {
      background: linear-gradient( to bottom, #28c7b4, #1d5ba1);
      h2 {
        background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1d5ba1), to(#0a1d34));
      }
    }
    
    
  }
}